import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";


import { Link } from "gatsby";

import SEO from "../../components/seo";
import * as CONST from "../../components/const";
import MoreLink from "../../components/morelink";

// const bg = "../../images/employment/bg.png";
const bg_h = "../../images/employment/bg_h.png";
const bg_v = "../../images/employment/bg_v.png";
const kv_h = "../../images/employment/kv_h.png";
const kv_v = "../../images/employment/kv_v.png";
const img1 = "../../images/employment/img1_rc.jpg";
const img2 = "../../images/employment/img2_rc.jpg";
const img3 = "../../images/employment/img3_rc.jpg";
const bg_text = "../../images/employment/bg_text2.png";

const footerLeft = "../../images/employment/young/footer_left.jpg";
const footerRight = "../../images/employment/senior/footer_right.jpg";


function IndexPage() {
  const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

  return (
    <Layout>
      <SEO
        description="当社が力を入れている研修制度は、「しっかり働く」ための土台作りとなります。自然に盛んになったクラブ活動は、「しっかり遊ぶ」環境、そして社員同士のコミュニケーションの場であると言えるでしょう。"
        title="Employment"
      />

      <section className={CONST.SECTION_PB_AREA_CLASS + " pb-10"}>
        <div className={CONST.KEY_VISUAL_OFFSET_TITLE_AREA_CLASS + " xl:text-white"}>
          <p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Employment information <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>採用情報</span></p>
        </div>
        <div className={CONST.KEY_VISUAL_LEFT_IMG_AREA_CLASS}>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS + " z-10"} src={kv_h} />
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.KEY_VISUAL_VARIABLE_IMG_CLASS + " z-10"} src={kv_v} />
        </div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_OFFSET_CLASS}></div>
        <div className={CONST.KEY_VISUAL_OFFSET_MESSAGE_AREA_RIGHT_CLASS + " font-light xl:pt-10 2xl:pt-10 3xl:pt-10 xl:pb-0 2xl:pb-0 3xl:pb-0 xl:pr-20 sm:text-lg xl:text-base sm:tracking-wider sm:leading-11 xl:leading-9"}>
          <div>
            <h1 className={CONST.H1_CLASS + " 3xl:mb-5"}>しっかり働き<br />しっかり遊ぶ</h1>
            <p className="my-14 lg:my-7 text-md sm:text-xl xl:text-lg">研修とクラブ活動がその原動力</p>
            <p>当社が力を入れている研修制度は、「しっかり働く」ための土台作りとなります。</p>
            <p>自然に盛んになったクラブ活動は、「しっかり遊ぶ」環境、そして社員同士のコミュニケーションの場であると言えるでしょう。</p>
            <MoreLink link_to="./requirements/" add_class="w-full xs:w-10/12 sm:w-6/12 xl:w-11/12" tag_link="true" text="Application Requirements" />
          </div>
        </div>
        {/* <div className={CONST.KV_BACK_GROUND_GLAY_AREA_CLASS}></div> */}
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.EMPLOYMENT_BACK_GROUND_PATTERN_CLASS} src={bg_h} />
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.EMPLOYMENT_BACK_GROUND_PATTERN_CLASS} src={bg_v} />
      </section>


      <section className={CONST.SECTION_AREA_CLASS + " 2xl:pt-72 3xl:pt-64"}>
        <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_SHOW_CLASS + CONST.EMPLOYMENT_BACK_GROUND_TEXT_CLASS} src={bg_text} />
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS + " z-40"}><span className="inline-block">あなたの</span><span className="inline-block">人間的魅力を</span><br />大切にします。</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className=" w-full z-10 -mb-10 xl:mb-0" src={img1} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS + " relative"}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>
              <p className="mb-3">あなたの人間的魅力を</p><p>大切にします。</p>
            </h2>
            <p>当社が定義するエンジニアにとって、技術力以上に重要なのがヒューマンスキルや人間的魅力です。</p>
            <p>技術は努力をすれば後からいくらでも身につけることが出来ます。また、精神的に追い込まれた状況で良い仕事はできません。時にはリラックスして自分本来の魅力を取戻し、共に生き生きと仕事をしましょう。</p>
          </div>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className={CONST.PC_HIDDEN_CLASS + CONST.EMPLOYMENT_BACK_GROUND_TEXT_CLASS} src={bg_text} />
        </div>
      </section>

      <section className={CONST.SECTION_AREA_REVERSE_CLASS}>
        <div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
          <div className="w-full">
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>
              <p className="mb-3">社内SEによる</p><p>専門教育。</p>
            </h2>
            <p>プログラム未経験者が多い新入社員研修では、先輩ＳＥが約３ヶ月の研修期間で皆さんを立派なプログラマに育て上げます。</p>
            <p>当社では入社前の専門知識は不問です。ただし、チャレンジ精神と向上心は持ち続けて下さい！（学校でプログラムを勉強した方も歓迎します）</p>
            <MoreLink link_to="./training/" add_class="" tag_link="true" />
          </div>
        </div>
        <div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>社内SEによる<br />専門教育。</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full  -mb-10 xl:mb-0" src={img2} />
        </div>
      </section>

      <section className={CONST.SECTION_PB_AREA_CLASS}>
        <div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
          <h2 className={CONST.H2_CLASS + CONST.PC_HIDDEN_CLASS}>将来の可能性は<br />実に多彩。</h2>
          <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full  -mb-10 xl:mb-0" src={img3} />
        </div>
        <div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
          <div>
            <h2 className={CONST.H2_CLASS + CONST.PC_SHOW_CLASS + " 3xl:tracking-wider"}>
              <p className="mb-3">将来の可能性は</p><p>実に多彩。</p>
            </h2>
            <p>当社は「独立系」システム開発会社で開発業務の幅が広いため、多彩な可能性が皆さんを待っています。将来、様々な業種の開発を経験したゼネラリストになったり、一つの業種のエキスパートになったり。また、マネジメント能力に長けたプロジェクトマネージャーとして活躍したり、技術力向上を追求した優秀なエンジニアとして周囲に頼りにされたり。一人ひとりに適したキャリアに進んで行くことが出来るでしょう。</p>
            <div className="hidden flex flex-wrap flex-row py-8">
              <div className="w-full md:w-6/12 lg:w-6/12 p-5">
                <Link key='senior' to='./senior/'>
                  <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerLeft} />
                </Link>
              </div>
              <div className="w-full md:w-6/12 p-5">
                <Link key='young' to='./young/'>
                  <StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerRight} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default IndexPage;
